/**
 * Suchwidget, bereitgestellt durch familienratgeber.de
 * Aktion Mensch e.V.
 */

(() => {
    const SCRIPT_NAME = 'familienratgeber-widget-adressen';

    const findScript = (scriptName) => {
        const re = new RegExp(scriptName);
        for (const s of document.scripts) {
            if (re.test(s.src)) return s;
        }
    };

    document.addEventListener('DOMContentLoaded', () => {
        const moduleScript = document.createElement('script');
        moduleScript.src = `${import.meta.env.VITE_SUCHWIDGET_BASE_PATH as string}dist/suchwidget/suchwidget-adressen.js`;
        moduleScript.type = 'module';
        document.head.appendChild(moduleScript);

        const suchwidget = document.createElement('familienratgeber-suchwidget-adressen');
        const target = findScript(`${SCRIPT_NAME}.js`);
        target.parentNode.replaceChild(suchwidget, target);
    });
})();
